import { createRouter, createWebHistory } from 'vue-router'
import { requireNoAuth } from '@/services/auth'
import Home from '@/views/Home.vue'
import store from '@/store/'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'CampaignCharts',
    component: () => import('../views/CampaignCharts.vue'),
    meta: {
      groupsAllowed: ['Canvassing Org Admin', 'Fundraiser'],
    },
    props: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/fundraiserPerformanceCharts',
    name: 'FundraiserPerformanceCharts',
    component: () => import('../views/FundraiserPerformanceCharts.vue'),
    meta: {
      groupsAllowed: ['Canvassing Org Admin'],
    },
    props: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/password/request-reset',
    name: 'RequestPasswordReset',
    component: () =>
      import(/* webpackChunkName: "requestReset" */ '../views/RequestPasswordReset.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "confirmReset" */ '../views/ResetPassword.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/npos',
    name: 'NPOList',
    component: () => import(/* webpackChunkName: "npoList" */ '../views/NPOList.vue'),
    meta: {
      groupsAllowed: ['Givaroo Staff'],
    },
  },
  {
    path: '/npo/:id',
    name: 'NPODetail',
    component: () => import(/* webpackChunkName: "npoDetail" */ '../views/NPODetail.vue'),
    props: true,
    meta: {
      groupsAllowed: ['NPO Admin', 'Givaroo Staff'],
    },
  },
  {
    path: '/campaigns',
    name: 'CampaignList',
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/CampaignList.vue'),
    props: {
      pageTitle: 'Campaigns',
    },
    meta: {
      groupsAllowed: ['NPO Admin', 'Givaroo Staff', 'Canvassing Org Admin', 'Fundraiser'],
    },
  },
  {
    path: '/campaign/:id',
    name: 'CampaignData',
    component: () => import(/* webpackChunkName: "campaignData" */ '../views/CampaignData.vue'),
    // props: {
    //   pageTitle: 'Campaign Data',
    // },
    meta: {
      groupsAllowed: ['NPO Admin', 'Givaroo Staff', 'Canvassing Org Admin'],
    },
  },
  {
    path: '/fundraisers',
    name: 'FundraiserList',
    component: () => import(/* webpackChunkName: "fundraisers" */ '../views/FundraiserList.vue'),
    props: {
      pageTitle: 'Fundraisers',
    },
    meta: {
      groupsAllowed: ['NPO Admin', 'Givaroo Staff', 'Canvassing Org Admin'],
    },
  },
  {
    path: '/donations',
    name: 'DonationList',
    component: () => import(/* webpackChunkName: "donationList" */ '../views/DonationList.vue'),
    props: {
      pageTitle: 'Donations',
    },
  },
  {
    path: '/leads',
    name: 'LeadList',
    component: () => import(/* webpackChunkName: "leadList" */ '../views/LeadList.vue'),
    props: {
      pageTitle: 'Leads',
    },
  },
  {
    path: '/campaign/:id/donate',
    name: 'CampaignDonate',
    component: () => import(/* webpackChunkName: "campaignDonate" */ '../views/CampaignDonate.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/npo-onboarding',
    name: 'NPOOnboarding',
    component: () => import(/* webpackChunkName: "npoOnboarding" */ '../views/NPOOnboarding.vue'),
  },
  {
    path: '/campaign/:id/lead',
    name: 'CampaignLead',
    component: () => import(/* webpackChunkName: "campaignLead" */ '../views/CampaignLead.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/sentry-test',
    name: 'SentryTest',
    component: () => import(/* webpackChunkName: "sentryTest" */ '../views/SentryTest.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import(/* webpackChunkName: "campaignData" */ '../views/ThankYou.vue'),
    props: {
      pageTitle: 'Thank You',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
})

// Checks user groups returned in user object against groups defined in meta object above
const checkPermission = (groupsAllowed, usersGroups) => {
  if (usersGroups && groupsAllowed) {
    for (let i = 0; i < usersGroups.length; i++) {
      for (let j = 0; j < groupsAllowed.length; j++) {
        if (usersGroups[i].includes(groupsAllowed[j])) {
          return true
        }
      }
    }
  }

  return false
}

// Navigation guards to route users to correct path based on permission type
router.beforeEach((to, from, next) => {
  const { groupsAllowed } = to.meta
  const { isLoggedIn } = store.getters
  const userGroups = store.getters.userGroups || false
  const isAllowedToView = checkPermission(groupsAllowed, userGroups)

  if (groupsAllowed && !isLoggedIn) {
    next({ name: 'Login' })
  } else if (groupsAllowed && !isAllowedToView) {
    next({ name: 'PageNotFound' })
  } else if (to.name === 'Login' && isLoggedIn) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
